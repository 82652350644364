// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';

type SigninReqBody = {
  email: string;
  password: string;
};

const handlers = [
  rest.post<SigninReqBody>('/signin', (req, res, ctx) => {
    const { email, password } = req.body;
    if (email === 'test@test.com' && password === '1q2w3e4r') {
      return res(ctx.json({ code: 'SUCCESS' }));
    }
    return res(ctx.status(401), ctx.json({ code: 'UNAUTHORIZED' }));
  }),
];

export default handlers;
