import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authorizeAtom } from 'state/atom';

import Header from 'components/_common/Header';
import HeroSection from './HeroSection';
import TeamSection from './TeamSection';

import styles from './Main.module.scss';

const Main = () => {
  const [isAuthorized, setIsAuthorized] = useRecoilState(authorizeAtom);

  useEffect(() => {
    setIsAuthorized(sessionStorage.getItem('isAuthorized'));
  }, [setIsAuthorized]);

  return (
    <div className={styles.container}>
      <Header />
      <main>
        <HeroSection />
        {isAuthorized && <TeamSection />}
      </main>
    </div>
  );
};

export default Main;
