import LoginContent from 'components/Login/LoginContent';

import styles from './Login.module.scss';

const Login = () => {
  return (
    <div className={styles.container}>
      <LoginContent />
    </div>
  );
};

export default Login;
