import { members } from 'constants/member';
import { Element } from 'react-scroll';
import styles from './TeamSection.module.scss';

const TeamSection = () => {
  const memberList = members.map((member) => {
    const { name, role, imgHash } = member;
    return (
      <ul className={styles.member} key={member.imgHash}>
        <li>
          <img className={styles.profileImg} src={`https://api.lorem.space/image/face?hash=${imgHash}`} alt='person' />
        </li>
        <li className={styles.name}>{name}</li>
        <li className={styles.role}>{role}</li>
      </ul>
    );
  });

  return (
    <Element id='team' name='team'>
      <section className={styles.container}>
        <header>
          <h1>Team Section</h1>
          <p>This section is a sample to demonstrate</p>
          <h2>BASIC STYLING SKILLS</h2>
        </header>
        <div className={styles.meberList}>{memberList}</div>
      </section>
    </Element>
  );
};

export default TeamSection;
