import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Logo } from 'assets/svgs/imgs';

import { authorizeAtom, inputEmailAtom } from 'state/atom';
import { emailValidation, passwordValidation } from 'utils/testValidation';
import { getAuth } from 'services/getAuth';

import Button from 'components/_common/Button';

import styles from './LoginContent.module.scss';

const LoginContent = () => {
  const [, setIsAuthorized] = useRecoilState(authorizeAtom);

  const [inputEmail, setInputEmail] = useRecoilState(inputEmailAtom);
  const [inputPassword, setInputPassword] = useState('');

  const [isEmail, setIsEmail] = useState(true);
  const [isPassword, setIsPassword] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await getAuth(inputEmail, inputPassword);
    if (result === 'SUCCESS') {
      navigate('/');
      sessionStorage.setItem('isAuthorized', 'isAuthorized');
      setIsAuthorized(sessionStorage.getItem('isAuthorized'));
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputEmail(e.currentTarget.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPassword(e.currentTarget.value);
  };

  const handleOnBlur = () => {
    if (emailValidation(inputEmail)) {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }

    if (passwordValidation(inputPassword)) {
      setIsPassword(true);
    } else {
      setIsPassword(false);
    }
  };

  useEffect(() => {
    setIsAuthorized(sessionStorage.getItem('isAuthorized'));
  }, [setIsAuthorized]);
  return (
    <div className={styles.container}>
      <NavLink to='/'>
        <Logo className={styles.logo} />
      </NavLink>
      <form className={styles.loginForm} action='submit' onSubmit={handleSubmit}>
        <div className={styles.wrapper}>
          <label htmlFor='email'>이메일</label>
          <input id='email' type='text' value={inputEmail} onChange={handleEmailChange} onBlur={handleOnBlur} />
          {!isEmail && <p className={styles.invalid}>올바른 형식의 이메일이 아닙니다</p>}
        </div>
        <div className={styles.wrapper}>
          <label htmlFor='password'>암호</label>
          <input
            id='password'
            type='password'
            value={inputPassword}
            onChange={handlePasswordChange}
            onBlur={handleOnBlur}
          />
          {!isPassword && <p className={styles.invalid}>최소 8자 이상을 입력해주세요</p>}
        </div>
        <Button className={styles.loginBtn} type='submit'>
          로그인
        </Button>
      </form>
    </div>
  );
};

export default LoginContent;
