import { Logo } from 'assets/svgs/imgs';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authorizeAtom, inputEmailAtom } from 'state/atom';
import styles from './Header.module.scss';
import Button from '../Button';

const Header = () => {
  const [isAuthorized, setIsAuthorized] = useRecoilState(authorizeAtom);
  const inputEmail = useRecoilValue(inputEmailAtom);

  const handleLogout = () => {
    setIsAuthorized(null);
    sessionStorage.removeItem('isAuthorized');
  };

  return (
    <header className={styles.container}>
      <NavLink to='/'>
        <Logo className={styles.logo} />
      </NavLink>
      {isAuthorized === null ? (
        <NavLink to='login'>
          <Button className={styles.loginBtn} onClick={handleLogout}>
            로그인
          </Button>
        </NavLink>
      ) : (
        <div>
          <span className={styles.email}>{inputEmail}</span>
          <Button className={styles.loginBtn} onClick={handleLogout}>
            로그아웃
          </Button>
        </div>
      )}
    </header>
  );
};

export default Header;
