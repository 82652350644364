import { atom } from 'recoil';

export const authorizeAtom = atom<string | null>({
  key: 'authorizeAtom',
  default: null,
});

export const inputEmailAtom = atom<string>({
  key: 'inputEmailAtom',
  default: '',
});
