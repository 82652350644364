import Button from 'components/_common/Button';
import { Link } from 'react-scroll';
import { useRecoilValue } from 'recoil';
import { authorizeAtom } from 'state/atom';
import styles from './HeroSection.module.scss';

const HeroSerction = () => {
  const isAuthorized = useRecoilValue(authorizeAtom);

  const handleStart = () => {
    // eslint-disable-next-line no-alert
    !isAuthorized && alert('로그인을 해주세요!');
  };

  return (
    <div className={styles.container}>
      <section className={styles.heroSection}>
        <h1>Hello!</h1>
        <p>
          Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti
          eaque aut repudiandae et a id nisi.
        </p>
        <Link to='team' type='button' className={styles.startBtn} smooth duration={1000}>
          <Button onClick={handleStart}>시작하기</Button>
        </Link>
      </section>
    </div>
  );
};

export default HeroSerction;
