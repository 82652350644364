import axios from 'axios';

export const getAuth = async (inputEmail: string, inputPassword: string) => {
  try {
    const res = await axios.post('/signin', {
      email: inputEmail,
      password: inputPassword,
    });
    return res.data.code;
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert('로그인에 실패하셨습니다.');
    return err;
  }
};
